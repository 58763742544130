import React, { Component } from 'react';
 
class Ticker2 extends Component {


    render() {

        return (

            <div class="ticker-wrap2">
            <div class="ticker2">
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
              <div class="ticker__item2">The Frenchie Royal NFTs</div>
            </div>
            </div>)
    }
}

export default Ticker2;