import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import me from '../assets/medium.png';
import twitter from '../assets/twitter-.png';
import discord from '../assets/discord.png';
import opensea from '../assets/os.png';

const os = () => {
    window.open("#");
}

const tweet = () => {
    window.open("#");
}

const tg = () => {
    window.open("#");
}

const element = () => {
    window.open("#");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">

                <div class="footer">

                    <div class="fH">THE FRENCHIE ROYALE NFT COLLECTION</div>

                    <div class="icons">
                        <img src={twitter} />
                        <img src={discord} />
                        <img src={opensea} />
                    </div>

                    <div class="copyright">Copyright © 2023 THE FRENCHIE ROYALE NFTs. All Rights Reserved</div>

                </div>

            </div>
        )
    }
}

export default Footer;

